<template>

  <div>
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg fill="#000" class="material-design-icon__svg" width="15" height="15" viewBox="0 0 24 24">
            <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
          </svg>
        </a>
      </div>
      <div class="text-dark width-available text-center">
        <router-link to="/">
          <img style="float: left; width: 110px" src="/img/chopLifeLogo.png" class="top-header-logo img-fluid"
            alt="Chopbet Logo" />
        </router-link>
      </div>
    </section>


    <div style="color: var(--darkBackground); padding: 5px">
      <h5> Responsible Gaming</h5>
      <p>
        Choplife offers you an exceptional online entertainment service. We cherish and care for our customers, and as
        such, we wholeheartedly endorse Responsible Gambling to ensure that you continue to enjoy a safe and controlled
        gaming environment.
      </p>


      <p>Please keep the following points in mind: </p>

      <ul>
        <li>
          Online games should be considered as entertainment, not as a means to generate income.
        </li>
        <li>
          Always monitor the time and money spent on the games.
        </li>
        <li>
          Never play to recover losses or to address financial troubles.
        </li>
        <li>
          Avoid borrowing money, selling possessions, or leaving bills unpaid to play.
        </li>
        <li>
          Share any potential gambling issues with your family, friends, and our responsible <router-link
            to="/customer-support"> <span style="color: var(--red);">support team</span> </router-link>
        </li>
      </ul>

      <p>
        At Choplife, we are dedicated to fostering a safe and enjoyable gaming environment. As part of our commitment to
        Responsible Gambling, we offer a self-exclusion tool—a powerful resource for those seeking additional control
        over their gaming habits.
      </p>

      <p>
        The self-exclusion tool allows you to take a proactive step in managing your gaming activity. If you feel the need to restrict or pause your gaming access, this tool empowers you to do so.
      </p>
      <p>
        Key features of self-exclusion:
      </p>
      <ul>
        <li>
          Temporary Exclusion: Set a break period to step away from gaming for a specific duration.
        </li>
        <li>
          Permanent Exclusion: Opt for a more long-term exclusion if you need a substantial break.
        </li>
      </ul>
      <p>
        To utilize the self-exclusion please contact our responsible support team.
      </p>
      <p>
        Remember, responsible gaming is a shared commitment. If you ever need assistance or have questions, our support team is here to help. Your well-being is our priority.
      </p>
    </div>


    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>

import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'Responsible',
  components: {

    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "responsible");
    this.reloadProfile();

  }

}
</script>